/* eslint-disable @typescript-eslint/no-explicit-any */
import http from "@/http";

class PlanoService {
  private endpoint = "planos";

  list(): Promise<any> {
    return http.get(`${this.endpoint}`);
  }

  find(id: string): Promise<any> {
    return http.get(`${this.endpoint}/${id}`);
  }

  create(data: any): Promise<any> {
    return http.post(`${this.endpoint}`, data);
  }

  update(id: string, data: any): Promise<any> {
    return http.put(`${this.endpoint}/${id}`, data);
  }

  delete(id: string): Promise<any> {
    return http.delete(`${this.endpoint}/${id}`);
  }
}

export default new PlanoService();
