
import { ref, defineComponent, onMounted } from "vue";
import TurmaColumn from "@/util/table-columns/TurmaColumn";
import TurmaForm from "@/components/admin/turmas/TurmaForm.vue";
import AddAlunoForm from "@/components/admin/turmas/AddAlunoForm.vue";
import Turma from "@/interfaces/Turma";
import useList from "@/modules/useList";
import store from "@/store";
import DivisaoService from "@/services/auth/DivisaoService";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import Plano from "@/interfaces/Plano";
import PlanoService from "@/services/admin/PlanoService";
import Dialog from 'quasar/src/plugins/Dialog.js';;
import BaseService from "@/services/admin/BaseService";

export default defineComponent({
  components: { TurmaForm, AddAlunoForm },
  setup() {
    const $q = useQuasar();
    const planos = ref<Plano[]>([]);
    const modalAddAlunoForm = ref();
    const {
      rows,
      loading,
      filter,
      columns,
      list,
      formatData,
      modalForm,
      openModalForm,
      confirmDelete,
    } = useList(
      store.getters.divisaoId
        ? "divisoes/turmas/" + store.getters.divisaoId
        : "turmas",
      TurmaColumn
    );

    const openAddAlunoModalForm = (row: Turma | null): void => {
      modalAddAlunoForm.value.open(row);
    };

    const getPlanos = (): void => {
      loading.value = true;
      if (store.getters.divisaoId) {
        DivisaoService.getPlanos(store.getters.divisaoId)
          .then((res) => {
            planos.value = res.data;
            if (!planos.value.length) {
              showNotify({
                type: "warning",
                message: "Não há planos para essa divisão",
              });
            }
          })
          .catch((err) => {
            console.error(err.response.data.message);
            showNotify({
              type: "negative",
              message: err.response.data.message,
            });
          })
          .finally(() => (loading.value = false));
      } else {
        PlanoService.list()
          .then((res) => {
            planos.value = res.data;
          })
          .catch((err) => {
            console.error(err.response.data.message);
            showNotify({
              type: "negative",
              message: err.response.data.message,
            });
          })
          .finally(() => (loading.value = false));
      }
    };

    const showNotify = (opts: any): void => {
      $q.notify(opts);
    };

    const changeStatus = (props: any):void => {
      const title = props.status ? 'Deseja desativar?' : 'Deseja ativar?';
      const message = props.status ? 'Ao desativar a turma todos os alunos serão desativados juntos' : 'Irá ativar a turma novamente'
      Dialog.create({
        title: title,
        message: message,
        ok: {
          color: "green",
          label: "ATUALIZAR",
        },
        cancel: {
          icon: "close",
          color: "primary",
          label: "CANCELAR",
        },
        persistent: true,
      }).onOk(() => {
        BaseService.status('turmas', props.id)
        .then(() => {
          props.status = !props.status;
          showNotify({
            type: "positive",
            message: `Turma salva com sucesso`,
          });
        });
      });
    }

    onMounted(() => {
      getPlanos();
    });

    return {
      rows,
      planos,
      loading,
      filter,
      columns,
      list,
      modalForm,
      openModalForm,
      formatData,
      modalAddAlunoForm,
      openAddAlunoModalForm,
      confirmDelete,
      changeStatus,
    };
  },
});
