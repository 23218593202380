
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { ref, defineComponent, onMounted } from "vue";
import { requiredField } from "@/util/form-rules";
import Turma from "@/interfaces/Turma";
import TurmaService from "@/services/admin/TurmaService";
import User from "@/interfaces/User";
import store from "@/store";
import BaseService from "@/services/admin/BaseService";
import FormLoading from "../../layout/FormLoading.vue";

interface Form {
  alunos: string[];
}

const baseForm: Form = {
  alunos: [],
};

export default defineComponent({
  components: { FormLoading },
  setup(props, { emit }) {
    const $q = useQuasar();
    const show = ref(false);
    const form = ref<Form>();
    const turma = ref<Turma>();
    const alunos = ref<User[]>([]);
    const alunosSelecionados = ref<User[]>([]);
    const alunosDisponiveis = ref<User[]>([]);
    const alunosSelect = ref<User[]>([]);
    const alunoSelecionado = ref<User>();
    const loading = ref<boolean>(false);

    const open = (data: Turma | null): void => {
      if (data) {
        turma.value = JSON.parse(JSON.stringify(data));
        if (turma.value?.id) {
          form.value = {
            alunos: turma.value.alunos,
          };
          if (data.alunos) {
            alunosDisponiveis.value = alunos.value.filter(
              (aluno) => !data.alunos.includes(aluno.id)
            );
            alunosSelecionados.value = alunos.value.filter((aluno) =>
              data.alunos.includes(aluno.id)
            );
            alunosSelect.value = alunosDisponiveis.value;
          }
        } else {
          form.value = JSON.parse(JSON.stringify(baseForm));
        }
      }
      show.value = true;
    };

    const submit = (): void => {
      if (turma.value?.id) {
        loading.value = true;
        TurmaService.addAlunos(turma.value.id, {
          alunos: alunosSelecionados.value.map((a) => a.id),
        })
          .then(() => {
            afterSubmit();
            showNotify({
              type: "positive",
              message: "Turma salva com sucesso",
            });
          })
          .catch((err) => {
            console.log(err);
            showNotify({
              type: "negative",
              message: "Erro ao salvar turma",
            });
          })
          .finally(() => (loading.value = false));
      }
    };

    const getAlunos = (): void => {
      loading.value = true;
      if (store.getters.divisaoId) {
        BaseService.list("divisoes/alunos/" + store.getters.divisaoId)
          .then((res) => {
            alunos.value = res.data;
          })
          .catch((err) => {
            console.error(err.response.data.message);
            showNotify({
              type: "negative",
              message: err.response.data.message,
            });
          })
          .finally(() => (loading.value = false));
      } else {
        BaseService.list("alunos")
          .then((res) => {
            alunos.value = res.data;
          })
          .catch((err) => {
            console.error(err.response.data.message);
            showNotify({
              type: "negative",
              message: err.response.data.message,
            });
          })
          .finally(() => (loading.value = false));
      }
    };

    const addAluno = (): void => {
      const countAlunos = alunosSelecionados.value.length + 1;
      if (turma?.value && turma?.value?.maximo_aluno != 0) {
        if (countAlunos > turma?.value?.maximo_aluno) {
          showNotify({
            type: "negative",
            message: `A quantidade máxima de ${turma?.value?.maximo_aluno} foi atingida.`,
          });
          return;
        }
      }
      if (alunoSelecionado.value) {
        let index = alunosDisponiveis.value.indexOf(alunoSelecionado.value);
        if (index !== -1) {
          alunosDisponiveis.value.splice(index, 1);
          alunosSelecionados.value.push(alunoSelecionado.value);
          sortAlunos();
        }
        showNotify({
          type: "positive",
          message: alunoSelecionado.value.nome + " adicionado",
        });
        alunoSelecionado.value = undefined;
      }
    };

    const removeAluno = (aluno: User): void => {
      let index = alunosSelecionados.value.indexOf(aluno);
      if (index !== -1) {
        showNotify({
          type: "positive",
          message: aluno.nome + " removido",
        });
        alunosSelecionados.value.splice(index, 1);
        alunosDisponiveis.value.push(aluno);
        sortAlunos();
      }
    };

    const filterFn = (val: string, update: any): void => {
      if (val === "") {
        update(() => {
          alunosSelect.value = alunosDisponiveis.value;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        alunosSelect.value = alunosDisponiveis.value.filter(
          (aluno) => aluno.nome.toLowerCase().indexOf(needle) > -1
        );
      });
    };

    const sortAlunos = (): void => {
      alunosSelecionados.value = alunosSelecionados.value.sort((a, b) =>
        a.nome.localeCompare(b.nome)
      );
      alunosDisponiveis.value = alunosDisponiveis.value.sort((a, b) =>
        a.nome.localeCompare(b.nome)
      );
    };

    const afterSubmit = (): void => {
      emit("update");
      show.value = false;
      form.value = baseForm;
    };

    const showNotify = (opts: any): void => {
      $q.notify(opts);
    };

    onMounted(() => {
      getAlunos();
    });

    return {
      show,
      open,
      form,
      turma,
      alunos,
      requiredField,
      loading,
      showNotify,
      getAlunos,
      submit,
      afterSubmit,
      alunosDisponiveis,
      alunosSelecionados,
      alunosSelect,
      filterFn,
      alunoSelecionado,
      addAluno,
      removeAluno,
      sortAlunos,
    };
  },
});
