import Turma from "@/interfaces/Turma";

const TurmaColumn = [
  {
    name: "actions",
    align: "center",
    label: "Ações",
    field: "",
    sortable: false,
  },
  {
    name: "nome",
    align: "left",
    label: "Nome",
    field: "nome",
    sortable: true,
  },
  {
    name: "alunos",
    align: "center",
    label: "Nº Alunos",
    field: (row: Turma) => {
      return row.alunos.length;
    },
    sortable: true,
  },
  {
    name: "maximo_aluno",
    align: "center",
    label: "Nº Máximo de Alunos",
    field: "maximo_aluno",
    sortable: true,
  },
  {
    name: "descricao",
    align: "left",
    label: "Descrição",
    field: (row: Turma) => {
      if (row.descricao.length > 50) {
        return row.descricao.substr(0, 50) + "...";
      }
      return row.descricao;
    },
    sortable: true,
  },
  {
    name: "status",
    align: "center",
    label: "Status",
    field: "status",
    sortable: true,
  },
  {
    name: "created_at",
    align: "center",
    label: "Data Cadastro",
    field: (row: Turma) => row.created_at,
    sortable: true,
  },
];

export default TurmaColumn;
